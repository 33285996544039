import React from "react";
import "./FaceCardsSection.css";
import cardNotFoundImg from '../card-not-found-outline.svg'
import aceCardImg from '../ace-face-card.svg'
import kingCardImg from '../king-face-card.svg'
import queenCardImg from '../queen-face-card.svg'
import jackCardImg from '../jack-face-card.svg'

function FaceCardsSection(props) {

  return <div>
    <hr />

    <h2>
      Face Cards Already Found
    </h2>

    <div className='face-cards-found-container'>

      <div className='card-found-column'>
        <img src={props.aceHasBeenFound === true ? aceCardImg : cardNotFoundImg} />
        <h3 className='face-card-name'>Ace</h3>
      </div>

      <div className='card-found-column'>
        <img src={props.kingHasBeenFound === true ? kingCardImg : cardNotFoundImg} />
        <h3 className='face-card-name'>King</h3>
      </div>

      <div className='card-found-column'>
        <img src={props.queenHasBeenFound === true ? queenCardImg : cardNotFoundImg} />
        <h3 className='face-card-name'>Queen</h3>
      </div>

      <div className='card-found-column'>
        <img src={props.jackHasBeenFound === true ? jackCardImg : cardNotFoundImg} />
        <h3 className='face-card-name'>Jack</h3>
      </div>

    </div>

    <hr />
  </div>
}

export default FaceCardsSection;
