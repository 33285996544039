import * as React from 'react'

const INITIAL_STATE_CARD_TO_BE_CHOSEN = 1;

const _CardToBeChosenContext = React.createContext();

function cardSelectionReducer(state, action) {

    switch (action.type) {
        case 'cardSelectionChange': {

            console.log('cardSelectionChange: ', action)

            return action.cardIndex;
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}

function CardToBeChosenProvider({ children }) {
    const [state, dispatch] = React.useReducer(cardSelectionReducer, INITIAL_STATE_CARD_TO_BE_CHOSEN)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <_CardToBeChosenContext.Provider value={value}>{children}</_CardToBeChosenContext.Provider>
}

function useCardToBeChosenContext() {
    const context = React.useContext(_CardToBeChosenContext)
    if (context === undefined) {
        throw new Error('useuseCardToBeChosenContext must be used within a CardToBeChosenProvider')
    }
    return context
}

export { useCardToBeChosenContext, CardToBeChosenProvider, cardSelectionReducer }