import React, { useState, useEffect } from 'react'
import { useCardSelectionContext } from '../state/bet-selection-content'
import { useFaceCardsFoundContext } from '../state/face-cards-found-context'
import './BetPanel.css'
import { ethers } from 'ethers';
import FaceCardsSection from '../FaceCardsSection/FaceCardsSection';
import JackpotPrize from '../JackpotPrize/JackpotPrize';
import MessageList from '../MessageList/MessageList';

import CCGG_all_ABI from '../contracts/CCGG_all.json'
import { useCardToBeChosenContext } from '../state/card-chosen';
import { useGameIsBeingCreatedContext } from '../state/game-is-being-created-context';

function BetPanel(props) {

  const something = useCardSelectionContext();

  const cardToBeChosenContext = useCardToBeChosenContext();

  const faceCardsFoundContext = useFaceCardsFoundContext();

  const gameIsBeingCreatedContext = useGameIsBeingCreatedContext();

  let betSizeLocal = 1

  // const [state, setState] = useState({
  //   guessingGameContract: undefined,
  //   provider: undefined,
  //   signer: undefined,

  //   jackpotCurrentSize: "",
  //   currentBoard: {},
  //   revealedCardsMap: {},
  //   aceAlreadyChosen: false,
  //   kingAlreadyChosen: false,
  //   queenAlreadyChosen: false,
  //   jackAlreadyChosen: false,

  // })

  const [guessingGameContract, setGuessingGameContract] = useState(undefined);
  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  const [jackpotCurrentSize, setJackpotCurrentSize] = useState("");
  const [eventLogs, setEventLogs] = useState([]);
  const [currentBoard, setCurrentBoard] = useState({});
  const [revealedCardsMap, setRevealedCardsMap] = useState({});
  const [aceAlreadyChosen, setAceAlreadyChosen] = useState(false);
  const [kingAlreadyChosen, setKingAlreadyChosen] = useState(false);
  const [queenAlreadyChosen, setQueenAlreadyChosen] = useState(false);
  const [jackAlreadyChosen, setJackAlreadyChosen] = useState(false);
  const [showBetSizeError, setShowBetSizeError] = useState(false);
  const [betSizeErrorMsg, setBetSizeErrorMsg] = useState('');
  const [showBetProcessingSpinner, setShowBetProcessingSpinner] = useState(false);
  const [showNotEnoughFundsForBet, setShowNotEnoughFundsForBet] = useState(false);
  const [notEnoughFundsForBetMsg, setNotEnoughFundsForBetMsg] = useState(false);

  // const [aceFoundIndex, setAceFoundIndex] = useState(-1);
  // const [kingFoundIndex, setKingFoundIndex] = useState(-1);
  // const [queenFoundIndex, setQueenFoundIndex] = useState(-1);
  // const [jackFoundIndex, setJackFoundIndex] = useState(-1);


  useEffect(async () => {


    // something.dispatch({ type: 'FOOBAR' })

    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`

    // TODO setup smart contract connection...

    // check if on mumbai or matic mainnet

    window.ethereum.enable();

    if (!window.ethereum) throw Error('no window.ethereum!')
    else {

      const provider = new ethers.providers.Web3Provider(window.ethereum)

      const signer = provider.getSigner();

      const href = window.location.href;

      console.log('current domain href: ', href);

      const testnetConst = '://testnet.'

      if (href.indexOf(testnetConst) >= 0) {
        console.log('ON THE TESTNET VERSION!')
      }

      const currentNetwork = await provider.getNetwork();

      console.log('currentNetwork: ', currentNetwork);

      const MATIC_MUMBAI_CHAIN_ID = 80001;

      if (currentNetwork.chainId !== MATIC_MUMBAI_CHAIN_ID) {
        // if (window.confirm('Oh no!\n\nYour RPC client is on an unsupported network!\n\nPlease switch to Polygon / Matic Mumbai Testnet.\n\n(Click "ok" to learn how)')) {
        //   window.location.href = 'https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/';
        // };
        return;
      }

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...

      // Mumbai
      const guessingGameContractAddress =
        '0x1FC7a39607214d66E1d4fC2fD639b41c605A184B'
      const guessingGameContract = new ethers.Contract(
        guessingGameContractAddress,
        CCGG_all_ABI.abi,
        provider,
      )

      console.log('contract: ', guessingGameContract)

      await getCurrentameState();

      async function getCurrentameState() {
        const currentGameState = await guessingGameContract.getCurrentGameState();

        console.log('currentGameState: ', currentGameState)

        const {
          gameIsBeingCreated,
          aceHasBeenFound,
          kingHasBeenFound,
          queenHasBeenFound,
          jackHasBeenFound,
          aceNum,
          kingNum,
          queenNum,
          jackNum,
          cardsChosen,
          currentLevel,
          jackpotSize,
          revealedCards,
          revealedCardValues
        } = currentGameState

        console.log('revealed cardValues');

        const revealedCardIntegers = revealedCards.map(cardBn => {
          return +ethers.utils.formatUnits(cardBn, 'wei')
        })

        console.log({ revealedCardIntegers })

        something.dispatch({ type: 'HEARD_CARDS_BURNED', burnedCards: revealedCardIntegers })

        console.log('heard aceNum: ', aceNum)

        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', ACE: aceNum })
        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', KING: kingNum })
        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', QUEEN: queenNum })
        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', JACK: jackNum })

        gameIsBeingCreatedContext.dispatch({ type: 'GAME_IS_BEING_CREATED', isReady: !gameIsBeingCreated })

        // cone

        const jackpotSizeInt = ethers.utils.formatUnits(jackpotSize, 'ether')
        console.log('heard jackpotSizeInt!! ', jackpotSizeInt)

        setGuessingGameContract(guessingGameContract);
        setProvider(provider);
        setSigner(signer);

        setJackpotCurrentSize(jackpotSizeInt);
        setAceAlreadyChosen(aceHasBeenFound);
        setKingAlreadyChosen(kingHasBeenFound);
        setQueenAlreadyChosen(queenHasBeenFound);
        setJackAlreadyChosen(jackHasBeenFound);

      }

      guessingGameContract.on('GuessSubmitted', async (cardGuessed, sender, betSize) => {

        console.log('GuessSubmitted')
        console.log('heard sender!! ', sender)
        const betSizeEther = ethers.utils.formatUnits(betSize, 'ether')
        console.log('heard betSizeEther!! ', betSizeEther)

        const betSizeWei = ethers.utils.parseEther(betSize.toString())
        console.log('heard betSizeWei!! ', betSizeWei)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt)

      })

      guessingGameContract.on('BoardIsReady', async () => {

        console.log('heard BoardIsReady event!')

        console.log('calling for new game state...')

        await getCurrentameState();

        // gameIsBeingCreatedContext.dispatch({ type: 'GAME_IS_BEING_CREATED', isReady: true })

      })

      guessingGameContract.on('AceFound', async (sender, cardGuessed, betSize) => {

        console.log('AceFound')
        console.log('heard sender!! ', sender)

        const betSizeInt = ethers.utils.formatUnits(betSize, 'ether')
        console.log('heard betSizeEther!! ', betSizeInt)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt)

        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', ACE: cardGuessedInt })
        something.dispatch({ type: 'HEARD_CARD_BURNED', cardIndex: cardGuessedInt })

        const currentUserAddress = await signer.getAddress();

        if (sender === currentUserAddress) {
          setShowBetProcessingSpinner(false);
          alert('\n\nYOU FOUND THE ACE!!\n\n')
        }

        setAceAlreadyChosen(true);
      })

      guessingGameContract.on('KingFound', async (sender, cardGuessed, betSize) => {
        console.log('KingFound')

        console.log('heard sender!! ', sender)

        const betSizeInt = ethers.utils.formatUnits(betSize, 'ether')
        console.log('heard betSizeInt!! ', betSizeInt)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt);

        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', KING: cardGuessedInt })
        something.dispatch({ type: 'HEARD_CARD_BURNED', cardIndex: cardGuessedInt })

        const currentUserAddress = await signer.getAddress();

        if (sender === currentUserAddress) {
          setShowBetProcessingSpinner(false);
          alert('\n\nYOU FOUND THE KING!!\n\n')
        }

        setKingAlreadyChosen(true);

      })

      guessingGameContract.on('QueenFound', async (sender, cardGuessed, betSize) => {
        console.log('QueenFound')

        console.log('heard sender!! ', sender)

        const betSizeInt = ethers.utils.formatUnits(betSize, 'ether')
        console.log('heard betSizeInt!! ', betSizeInt)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt)

        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', QUEEN: cardGuessedInt })
        something.dispatch({ type: 'HEARD_CARD_BURNED', cardIndex: cardGuessedInt })

        console.log('address: ' + provider._address)

        const currentUserAddress = await signer.getAddress();

        if (sender === currentUserAddress) {
          setShowBetProcessingSpinner(false);
          alert('\n\nYOU FOUND THE QUEEN!!\n\n')
        }

        setQueenAlreadyChosen(true);
      })

      guessingGameContract.on('JackFound', async (sender, cardGuessed, betSize) => {
        console.log('JackFound')

        console.log('heard sender!! ', sender)

        const betSizeInt = ethers.utils.formatUnits(betSize, 'ether')
        console.log('heard betSizeInt!! ', betSizeInt)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt)

        faceCardsFoundContext.dispatch({ type: 'FACE_CARD_FOUND', JACK: cardGuessedInt })
        something.dispatch({ type: 'HEARD_CARD_BURNED', cardIndex: cardGuessedInt })

        const currentUserAddress = await signer.getAddress();

        if (sender === currentUserAddress) {
          setShowBetProcessingSpinner(false);
          alert('\n\nYOU FOUND THE JACK!!\n\n')
        }

        setJackAlreadyChosen(true);
        setJackpotCurrentSize(1.0);
      })

      guessingGameContract.on('JackpotSizeIncrease', async (sender, cardGuessed, jackpotSize, jackpotIncrease) => {
        console.log('JackpotSizeIncrease')

        console.log('heard sender!! ', sender)

        const jackpotSizeInt = ethers.utils.formatUnits(jackpotSize, 'ether')
        console.log('heard jackpotSizeInt!!! ', jackpotSizeInt)
        console.log('Jackpot int:  ', jackpotSizeInt)

        const jackpotIncreaseInt = ethers.utils.formatUnits(jackpotIncrease, 'ether')
        console.log('heard jackpotIncreaseInt!! ', jackpotIncreaseInt)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt)

        setJackpotCurrentSize(jackpotSizeInt);

        something.dispatch({ type: 'HEARD_CARD_BURNED', cardIndex: cardGuessedInt })

        const newLog = '- Jackpot increased by ( ' + jackpotIncreaseInt + ' ) matic from guess of card ( ' + cardGuessedInt + ' ) by user: ' + sender;

        const newEventLog = [newLog, ...eventLogs]

        // if (sender === currentUserAddress) {
        //   setShowBetProcessingSpinner(false);
        //   alert('\n\nBetter luck next time!\n\nYour guess ( ' + cardGuessedInt + ' ) was not a face card!')
        // }

        setEventLogs(newEventLog)
      })

      guessingGameContract.on('CardRevealed', async (cardGuessed, cardValue, guessedBy) => {
        console.log('CardRevealed')

        console.log('guessedBy!! ', guessedBy)
        console.log('cardValue: ', cardValue)

        const cardGuessedInt = ethers.utils.formatUnits(cardGuessed, 'wei')
        console.log('heard cardGuessedInt!! ', cardGuessedInt);

        // const newRevealedCardsMap = { ...(state.revealedCardsMap) };

        // newRevealedCardsMap[cardGuessed] = cardValue;

        // console.log('newRevealedCards: ', newRevealedCardsMap)

        // setState({ ...state, revealedCardsMap: newRevealedCardsMap });

        const currentUserAddress = await signer.getAddress();

        console.log('card Value: ', cardValue)

        if (guessedBy === currentUserAddress && cardValue === 'empty') {
          setShowBetProcessingSpinner(false);
          alert('\n\nBetter luck next time!\n\nYour guess ( ' + cardGuessedInt + ' ) was not a face card!')
        }

      })

      guessingGameContract.on('GameOver', async () => {

        console.log('GameOver')

        gameIsBeingCreatedContext.dispatch({ type: 'GAME_IS_BEING_CREATED', isReady: false })

        faceCardsFoundContext.dispatch({ type: 'CLEAR_ALL_FACE_CARDS' })

        something.dispatch({ type: 'CLEAR_ALL_BURNED_CARDS' })

        alert('This game is overrr!\n\nBut don\'t worry... we\'re starting up another one right now!')

      })

      guessingGameContract.on('NewGameCreated', async () => {
        console.log('NewGameCreated')

      })

      // if (typeof window.web3 !== 'undefined') {
      //   console.log('undefined')
      //   this.web3 = new Web3(this.web3.currentProvider);
      // } else {
      //   console.log('ok!')
      //   // set the provider you want from Web3.providers
      // this.web3 = new Web3(new Web3.providers.HttpProvider("https://rinkeby.infura.io/v3/2fad09ea5c184cff844b1467c8616d6b"));

      // console.log('here address: ', this.currentUserAddress)

      // await this.sendCryptoToContract()
      // await this.storeNewFireInDb();

      // const connectedAccount = this.currentUserAddress;

      // locally deplopyed

      // locally deplopyed
      // const sprayerPayerAbi = '0x123'

      // const guessingGameContract = new this.web3.eth.Contract([GuessingGameContractAbi], guessingGameContractAddress);

      // var Coursetro = CoursetroContract.at('PASTE CONTRACT ADDRESS HERE');
      // console.log(guessingGameContract);
    }
  }, [])

  // sprayerPayer.submitGuess({ value: + });

  function betSizeInputChanged(ev) {
    // console.log('ok: ', ev)

    // console.log(ev.value)
    // console.log(ev.data)
    // console.log(ev.target)
    console.log('new bet size: ' + ev.target.value);

    setShowNotEnoughFundsForBet(false);

    if (ev.target.value < 1) {
      setShowBetSizeError(true);
      setBetSizeErrorMsg('Please bet at least 1 MATIC!');
    }

    else if (ev.target.value > 10) {
      setShowBetSizeError(true);
      setBetSizeErrorMsg('Please bet at most 10 MATIC!');
    }

    else {
      setShowBetSizeError(false);
      setBetSizeErrorMsg('');
    }

    // console.log(ev.nativeEvent)

    // cardToBeChosenContext.dispatch({ type: 'cardSelectionChange', cardIndex: ev.target.value })
  }

  function inputChanged(ev) {
    console.log('ok: ', ev)

    console.log(ev.value)
    console.log(ev.data)
    console.log(ev.target)
    console.log(ev.target.value)
    console.log(ev.nativeEvent)

    cardToBeChosenContext.dispatch({ type: 'cardSelectionChange', cardIndex: ev.target.value })
  }

  async function submitGuess() {
    console.log('submitting guess')
    const cardGuessed = document.getElementById('guess').value
    console.log('guess: ', cardGuessed)
    const betSize = document.getElementById('betSize').value
    console.log('betSize: ', betSize)

    const currentUserAddress = await signer.getAddress();

    let maticBalance = await provider.getBalance(currentUserAddress);

    console.log('current balance: ', maticBalance)

    const overrides = { value: ethers.utils.parseEther(betSize) }

    const submitGuessPromise = guessingGameContract
      .connect(signer)
      .submitGuess(cardGuessed, overrides)
      .then( result => {

        setShowBetProcessingSpinner(true);
        setShowNotEnoughFundsForBet(false);
        
        // setBetWasSuccessfullyProcessed(false)

        // setTimeout(() => {

        //   if (!betWasSuccessfullyProcessed) {
        //     setShowBetProcessingSpinner(false);
        //     setBetProcessingFailed(false);

            

        //   }

        // }, 30_000)

      })
      .catch(err => {
        
        setShowBetProcessingSpinner(false);
        console.log('submit guess failed ' + err)

        if (err.data && err.data.message) {

          setShowNotEnoughFundsForBet(true);
          setNotEnoughFundsForBetMsg(err.data.message);

        }

      })

      // try {
      //   const result = await Promise.race([p1, p2]);
      // } catch(e) {
      //   // e = p2
      // }

  }

  return (
    <div>

      <JackpotPrize jackpotSize={jackpotCurrentSize} />
      <FaceCardsSection
        aceHasBeenFound={aceAlreadyChosen}
        kingHasBeenFound={kingAlreadyChosen}
        queenHasBeenFound={queenAlreadyChosen}
        jackHasBeenFound={jackAlreadyChosen}
      />

      <br />

      <label for="guess">Card to Guess:</label> &nbsp;

      <input
        className="bet-input"
        type="number"
        id="guess"
        name="guess"
        defaultValue="1"
        min="1"
        max="100"
        value={cardToBeChosenContext.state}
        onChange={(event) => inputChanged(event)}
      ></input>
      <br />
      <label for="betSize">Bet Size:&nbsp;</label>
      <input
        className="bet-input"
        type="number"
        id="betSize"
        name="betSize"
        defaultValue="1"
        min="1"
        max="10"
        onChange={(event) => betSizeInputChanged(event)}
      // value="betSizeLocal"
      ></input>

      {
        showBetProcessingSpinner === true &&
        <div>
        <div className="loader">Loading...</div>
        <h3>Your Guess Is Being Processed!</h3>
        <p>Please be patient... the blockchain is slow! 😅</p>
        </div>
      }

      {showNotEnoughFundsForBet && <p className='error-msg-text'>
        {notEnoughFundsForBetMsg}
      </p>}

      {showBetSizeError && <p className='error-msg-text'>
        {betSizeErrorMsg}
      </p>}

      <br />
      {/* <label for="currency"></label>
      <select name="currency" id="currency">
        <option value="WETH">WETH</option>
        <option value="WBTC">WBTC</option>
        <option value="DAI">DAI</option>
        <option value="USDC">USDC</option>
        <option value="USDT">USDT</option>
      </select> */}
      <button className={showBetSizeError || showBetProcessingSpinner ? 'disabled-mode submit-guess-btn' : 'submit-guess-btn'} onClick={() => submitGuess()}
        disabled={showBetSizeError || showBetProcessingSpinner}>
        Submit Guess!
      </button>
      <br />
      <br />

      <hr />

      <h3>
        Already Guessed:
      </h3>

      <div className='already-guessed-nums'>
        {Object.keys(something.state.burnedCards).join(', ')}
      </div>

      {/* {JSON.stringify(something)} */}


      <MessageList messages={eventLogs} />

    </div>
  )
}

export default BetPanel
// export default withRouter(BetPanel)
