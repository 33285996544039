import * as React from 'react'

const MAX_BET_SIZE = 5;
const MIN_BET_SIZE = 1;

const MAX_CARD_INDEX = 99;
const MIN_CARD_INDEX = 0;

const INITIAL_STATE = { burnedCards: {} }

const INITIAL_STATE_CARD_TO_BE_CHOSEN = 1;

const CardSelectionContext = React.createContext();

// const CardToBeChosenContext = React.createContext();

function betChangeReducer(state, action) {
    switch (action.type) {
        case 'changeBetSize': {

            console.log('handling: ', action)

            if (action.betbetSize < MIN_BET_SIZE)
                return { betSize: MIN_BET_SIZE }

            if (action.betbetSize > MAX_BET_SIZE)
                return { betSize: MAX_BET_SIZE }

            return { ...state, betSize: action.betSize }

        }
        // case 'decrement': {
        //     return { count: state.count - 1 }
        // }
        case 'cardChosen': {

            console.log('handling: ', action)

            if (action.cardIndex < MIN_CARD_INDEX)
                return { cardIndex: MIN_CARD_INDEX }

            if (action.cardIndex > MAX_CARD_INDEX)
                return { cardIndex: MAX_CARD_INDEX }

            return { ...state, cardIndex: action.cardIndex }
        }

        // case 'FOOBAR': {
        //     return { ...state, 1: 'hello', 2: true }
        // }

        case 'HEARD_CARD_BURNED': {

            const newState = { ...state };

            newState.burnedCards[action.cardIndex] = true;

            return newState;
        }

        case 'HEARD_CARDS_BURNED': {

            const newState = { ...state };

            action.burnedCards.forEach(cardIndex => {
                newState.burnedCards[cardIndex] = true;
            })

            return newState;
        }

        case 'CLEAR_ALL_BURNED_CARDS':
            return { ...state, burnedCards: {} };

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}


function cardSelectionReducer(state, action) {

    switch (action.type) {
        case 'cardSelectionChange': {

            console.log('cardSelectionChange: ', action)

            return action.cardIndex;
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}

function CardSelectionProvider({ children }) {
    const [state, dispatch] = React.useReducer(betChangeReducer, INITIAL_STATE)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <CardSelectionContext.Provider value={value}>{children}</CardSelectionContext.Provider>
}

// function CardToBeChosenContext({ children }) {
//     const [state, dispatch] = React.useReducer(betChangeReducer, INITIAL_STATE_CARD_TO_BE_CHOSEN)
//     // NOTE: you *might* need to memoize this value
//     // Learn more in http://kcd.im/optimize-context
//     const value = { state, dispatch }
//     return <CardToBeChosenContext.Provider value={value}>{children}</CardToBeChosenContext.Provider>
// }

function useCardSelectionContext() {
    const context = React.useContext(CardSelectionContext)
    if (context === undefined) {
        throw new Error('useuseCardSelectionContext must be used within a CardSelectionProvider')
    }
    return context
}

// function useCardToBeChosenContext() {
//     const context = React.useContext(CardToBeChosenContext)
//     if (context === undefined) {
//         throw new Error('useuseCardToBeChosenContext must be used within a CardToBeChosenProvider')
//     }
//     return context
// }

// export { CardSelectionProvider, useCardSelectionContext, useCardToBeChosenContext }
export { CardSelectionProvider, useCardSelectionContext }