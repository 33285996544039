import React, { useState, useEffect } from 'react'
import "./TopHeaderBar.css";
import { ethers } from 'ethers';

function TopHeaderBar() {
  
  const [isTestnet, setIsTestnet] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(0);
  
  const MATIC_MUMBAI_CHAIN_ID = 80001;
  
  useEffect(async () => {
    
    const href = window.location.href;
  
    console.log('current domain href: ', href);
  
    const testnetConst = '://testnet.'
  
    if (href.indexOf(testnetConst) >= 0) {
      console.log('ON THE TESTNET VERSION!')
      setIsTestnet(true);
    }
  
    const provider = new ethers.providers.Web3Provider(window.ethereum)
  
    const currentNetwork = await provider.getNetwork();
    setCurrentNetwork(currentNetwork.chainId);
  
    console.log('currentNetwork: ', currentNetwork);
  
    
  })

  return <div className="top-header-container">

    {/* {currentNetwork} */}

    Network:&nbsp;

    {currentNetwork === MATIC_MUMBAI_CHAIN_ID && <div>
        Mumbai Testnet
        <br/>
        <p>(Go ahead a place some bets. It's all just testnet Matic!)</p>
        <br/>
        {/* <button>Switch to Polygon Mainnet</button> */}
      </div>}

    {!currentNetwork === MATIC_MUMBAI_CHAIN_ID && <div>
      Polygon Mainnet
      <p>Play and win real Matic tokens!!</p>
      {/* <button>Switch to Polygon Testnet</button> */}
    </div>}

    {/* {isTestnet && <div>
        Mumbai Testnet
        <p>(Go ahead a place some bets. It's all just testnet Matic!)</p>
        <button>Switch to Polygon Mainnet</button>
      </div>}

    {!isTestnet && <div>
      Polygon Mainnet
      <p>Play and win real Matic tokens!!</p>
      <button>Switch to Polygon Testnet</button>
    </div>} */}

  </div>
}

export default TopHeaderBar;
