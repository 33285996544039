import React, { Link } from 'react'
import './Footer.css'

function Footer() {

  return (
    <div className='footer-container'>

      <div className='footer-left'>
        A cool thing by <a href='https://twitter.com/jimlynchcodes'>Jim</a>
      </div>

      <div className='footer-right'>
      {/* <Link to={{ pathname: "www.twitter.com/jimlynchcodes" }} target="_blank" /> */}
        <a href='http://www.jimlynchcodes.com'>Blog</a>
        &nbsp; | &nbsp;
        <a href='https://medium.com'>FAQ</a>
        &nbsp; | &nbsp;
        <a href='https://discord.gg/qqSNB2Rc'>Discord</a>
      </div>

    </div>
  )
}

export default Footer;
