import * as React from 'react'
import { ethers } from 'ethers';

const GameIsBeingCreatedContext = React.createContext();

const INITIAL_STATE = { isReady: false }

function gameIsBeingCreatedReducer(state, action) {

    switch (action.type) {
        case 'GAME_IS_BEING_CREATED': {
            return { isReady: action.isReady };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}

function GameIsBeingCreatedProvider({ children }) {
    const [state, dispatch] = React.useReducer(gameIsBeingCreatedReducer, INITIAL_STATE)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <GameIsBeingCreatedContext.Provider value={value}>{children}</GameIsBeingCreatedContext.Provider>
}

function useGameIsBeingCreatedContext() {
    const context = React.useContext(GameIsBeingCreatedContext)
    if (context === undefined) {
        throw new Error('useGameIsBeingCreatedContext must be used within a CardsFoundContextProvider')
    }
    return context
}

export { GameIsBeingCreatedProvider, useGameIsBeingCreatedContext }