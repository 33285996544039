import React from 'react'
import './CardBoard.css'
import faceDownCardImg from '../Card_back_05a.svg'
import aceCardImg from '../ace-face-card.svg'
import kingCardImg from '../king-face-card.svg'
import queenCardImg from '../queen-face-card.svg'
import jackCardImg from '../jack-face-card.svg'
import { useFaceCardsFoundContext } from '../state/face-cards-found-context';
import { useCardSelectionContext } from '../state/bet-selection-content';
import { useCardToBeChosenContext } from '../state/card-chosen';
import { useGameIsBeingCreatedContext } from '../state/game-is-being-created-context'


function CardBoard() {

  console.log('card board created...');

  // const CountContext = React.createContext()

  const cardContext = useCardSelectionContext();

  // const [cardToBeChosenContext, setCardToBeChosenContext] = useCardToBeChosenContext();

  const cardToBeChosenContext = useCardToBeChosenContext();

  const faceCardsFoundContext = useFaceCardsFoundContext();

  const gameIsBeingCreatedContext = useGameIsBeingCreatedContext();

  console.log({ faceCardsFoundContext })
  console.log({ gameIsBeingCreatedContext })

  function cardClicked(index) {
    console.log('card clicked: ', index);

    cardToBeChosenContext.dispatch({ type: 'cardSelectionChange', cardIndex: index })
  }

  return (
    <div>

      {gameIsBeingCreatedContext.state.isReady === false &&
        <p>
          Connect your wallet to play!
        </p>
        
      }

      <div className="card-board">

        {/* burn cards */}
        {/* {cardContext} */}
        {/* {JSON.stringify(cardContext.state.burnedCards)} */}


        {gameIsBeingCreatedContext.state.isReady &&

          [...Array(100).keys()].map((_index) => {

            const index = _index + 1;

            return cardContext.state.burnedCards &&
              <div className={"card-container"} key={'burned-card-' + _index}

                onClick={() => cardClicked(index)}>

                <div >
                  {/* {faceCardsFoundContext.state.ACE} */}


                  <div className={cardContext.state.burnedCards[index] === true ? 'hidden' : ' '}>
                    {/* {+faceCardsFoundContext.state.ACE !== index &&
                    +faceCardsFoundContext.state.KING !== index &&
                    + faceCardsFoundContext.state.QUEEN !== index &&
                    + faceCardsFoundContext.state.JACK !== index && */}
                    <h1 className="centered-overlap-text">{index}</h1>
                    {/* } */}
                    {/* {faceCardsFoundContext.state.ACE === index && <img
                  src={aceCardImg}
                  className="cardImg"
                  alt="aceCardImg"
                />} */}
                    {/* {faceCardsFoundContext.state.JACK}
               {typeof faceCardsFoundContext.state.JACK}
               {typeof index} */}

                    {+faceCardsFoundContext.state.ACE !== index &&
                      +faceCardsFoundContext.state.KING !== index &&
                      + faceCardsFoundContext.state.QUEEN !== index &&
                      + faceCardsFoundContext.state.JACK !== index &&
                      < img
                        src={faceDownCardImg}
                        className="cardImg"
                        alt="faceDownCardImg"
                      />}
                  </div>



                  {+faceCardsFoundContext.state.JACK === index && <img
                    src={jackCardImg}
                    className="cardImg"
                    alt="jackCardImg"
                  />}
                  {+faceCardsFoundContext.state.ACE === index && <img
                    src={aceCardImg}
                    className="cardImg"
                    alt="aceCardImg"
                  />}
                  {+faceCardsFoundContext.state.KING === index && <img
                    src={kingCardImg}
                    className="cardImg"
                    alt="kingCardImg"
                  />}
                  {+faceCardsFoundContext.state.QUEEN === index && <img
                    src={queenCardImg}
                    className="cardImg"
                    alt="queenCardImg"
                  />}


                </div>
              </div>

          })}
      </div>
      <br />
    </div>
  )
}

export default CardBoard
