import React from "react";
import "./JackpotPrize.css";
import { ethers } from 'ethers';

function JackpotPrize(props) {

  // const jackpotSizeEther = ethers.utils.formatUnits(props.jackpotSize, 'ether')

  return <div>
    <hr />
    <h2>
      Current Jackpot Size:
    </h2>
    {/* {jackpotSizeEther} MATIC */}
    
    <div key={props.jackpotSize}>{props.jackpotSize} MATIC</div>

    <br />
    <br />
  </div>;
}

export default JackpotPrize;
