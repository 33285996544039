import React from "react";
import "./MessageList.css";

function MessageList(props) {

  return <div>
    <br />
    <hr />
    <br />
    {/* <h3>

      Event Log:
    </h3>

    {props.messages && props.messages.length && 
      props.messages.map( (message, i) => {

        return <p key={'event-log-' + i}>
          {message}
        </p>

      })} */}

    <p>

    </p>


    <br />
    <br />
  </div>;
}

export default MessageList;
