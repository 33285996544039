// import logo from './logo.svg';
import logo from './CCGG-logo-small.png';
import sadFace from './sad-face.png';
import blueRobot from './Blue-Robot.svg';
import './App.css';
import TopHeaderBar from './TopHeaderBar/TopHeaderBar';
import CardBoard from './CardBoard/CardBoard';
import BetPanel from './BetPanel/BetPanel';
import { FaceCardsFoundProvider } from './state/face-cards-found-context';
import { CardSelectionProvider } from './state/bet-selection-content';
import { CardToBeChosenProvider } from './state/card-chosen';
import { GameIsBeingCreatedProvider } from './state/game-is-being-created-context';
import Footer from './Footer/Footer';
import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers';

// import ReactGA from 'react-ga';

// const TRACKING_ID = "G-E01JKLFR4V";
// ReactGA.initialize(TRACKING_ID);

function App() {

  const [showingHelpPanel, setShowingHelpPanel] = useState(false);

  function toggleHelp() {

    console.log('help: ', showingHelpPanel)

    if (showingHelpPanel)
      setShowingHelpPanel(false);
    else
      setShowingHelpPanel(true);

  }

  const [currentChainId, setCurrentChainId] = useState(0);

  const MATIC_MUMBAI_CHAIN_ID = 80001;

  useEffect(async () => {

    const href = window.location.href;

    console.log('current domain href: ', href);

    const provider = new ethers.providers.Web3Provider(window.ethereum)

    const currentNetwork = await provider.getNetwork();
    setCurrentChainId(currentNetwork.chainId);

    console.log('currentNetwork: ', currentNetwork);


  })

  return (
    <div className="App">

      <header className="App-header">
        <div className="header-menu-bar">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 style={{ margin: "30px" }}>Crypto Card Guessing Game</h1>
          <button className="help-button" onClick={() => toggleHelp()}>?</button>
        </div>

        <br />

        {showingHelpPanel &&
          <div className="help-panel">

            <button onClick={() => toggleHelp()}>Close Help</button>
            <h1>
              How To Play
            </h1>

            <p>
              1. Choose a card (between 1 and 100)
            </p>

            <p>
              2. Place a bet (between 10 MATIC).
            </p>

            <p>
              3. If you find a jack, you win the progressive jackpot!
            </p>

            <p>
              4. If you find a face card, you win your bet times a muliplier!
            </p>

            <p>
              Your payout multiplier is based on the number of cards on the board:
            </p>

            * If 75+ cards left:  Ace 25x, King 15x, Queen 10x
            <br />
            <br />

            * If 50 - 75 cards left:  Ace 15x, King 10x, Queen 5x
            <br />
            <br />

            * If 25 - 50 cards left:  Ace 25x, King 15x, Queen 10x
            <br />
            <br />

            * If 0 - 25 cards left:  Ace 25x, King 15x, Queen 10x
            <br />

            <p>
              5. Face cards only pay the first user to guess them each game.
            </p>

            <p>
              6. Once all four face cards are found, the game ends and a new one begins!
            </p>

          </div>
        }

        <CardToBeChosenProvider>
          <CardSelectionProvider>
            <FaceCardsFoundProvider>
              <GameIsBeingCreatedProvider>

                {currentChainId === 80001 &&
                  <span>
                    <TopHeaderBar />
                    <BetPanel />
                    <CardBoard />
                  </span>
                }

                {currentChainId === 137 &&
                  <div className='error-panel'>
                    <h1>Coming to Mainnet Soon!</h1>
                    <div className='img-row'>
                      <img src={blueRobot} className='robot-img' />
                    </div>
                    <p>We've not yet released the smart contract on Polygon Mainnet, but it's coming very soon!</p>
                    <br/>
                    <p>In the meantime, please switch your RPC client to the Polygon Mumbai Testnet to play!</p>
                    <br/>
                  </div>
                }

                {currentChainId !== 137 && currentChainId !== 80001 &&
                  <div className='error-panel'>
                    <h1>Whoops, Wrong Network!</h1>
                    <div className='img-row'>
                      <img src={sadFace} className='sad-img' />
                    </div>
                    <p>Please change your RPC client to the Polygon Mumbai Testnet!</p>
                  </div>
                }


              </GameIsBeingCreatedProvider>
            </FaceCardsFoundProvider>
          </CardSelectionProvider>
        </CardToBeChosenProvider>
        <Footer></Footer>
      </header>
    </div>
  );
}

export default App;
