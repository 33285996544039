import * as React from 'react'
import { ethers } from 'ethers';

const FaceCardsFoundContext = React.createContext();

const INITIAL_STATE = {
    'ACE': -1,
    'KING': -1,
    'QUEEN': -1,
    'JACK': -1
}

function cardFoundReducer(state, action) {

    switch (action.type) {
        case 'FACE_CARD_FOUND': {

            console.log('handling: ', action)

            const newState = {
                'ACE': state.ACE,
                'KING': state.KING,
                'QUEEN': state.QUEEN,
                'JACK': state.JACK
            }

            if ({
                'ACE': action.ACE || state.ACE,
                'KING': action.KING || state.KING,
                'QUEEN': action.QUEEN || state.QUEEN,
                'JACK': action.JACK || state.JACK,
            })


            if (action.ACE) {
                newState.ACE = ethers.utils.formatUnits(action.ACE, 'wei');
            }

            if (action.KING) {
                newState.KING = ethers.utils.formatUnits(action.KING, 'wei');
            }

            if (action.QUEEN) {
                newState.QUEEN = ethers.utils.formatUnits(action.QUEEN, 'wei');
            }

            if (action.JACK) {
                newState.JACK = ethers.utils.formatUnits(action.JACK, 'wei');
            }

            return newState;

        }

        case 'CLEAR_ALL_FACE_CARDS':
            return INITIAL_STATE;

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}

function FaceCardsFoundProvider({ children }) {
    const [state, dispatch] = React.useReducer(cardFoundReducer, INITIAL_STATE)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <FaceCardsFoundContext.Provider value={value}>{children}</FaceCardsFoundContext.Provider>
}

function useFaceCardsFoundContext() {
    const context = React.useContext(FaceCardsFoundContext)
    if (context === undefined) {
        throw new Error('useFaceCardsFoundContext must be used within a CardsFoundContextProvider')
    }
    return context
}

export { FaceCardsFoundProvider, useFaceCardsFoundContext }